import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';
import { Provider } from 'react-redux';

import { i18n } from '@formue-app/core';

import App from './App';

import { store } from './store';

ReactModal.setAppElement('#root');

(async () => {
  await i18n.init();
  const root = createRoot(document.getElementById('root'));

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
})();
