import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { PageWrapper } from './base/PageWrapper';

import { accent } from '@formue-app/web/src/constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_20,
  SPACING_24,
  SPACING_32,
  SPACING_64,
  SPACING_8,
} from '@formue-app/web/src/constants/spacing';

import {
  H1Small,
  Paragraph,
  ParagraphSmall,
} from '@formue-app/web/src/components/texts';
import { SpriteIcon } from '@formue-app/web/src/components/common/SpriteIcon';
import { Button } from '@formue-app/web/src/components/buttons';

import { AppStoreBadge } from '@formue-app/web/src/components/storeBadges/AppStoreBadge';
import { PlayStoreBadge } from '@formue-app/web/src/components/storeBadges/PlayStoreBadge';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_20};
  margin-top: ${SPACING_64};
  margin-bottom: ${SPACING_24};
  text-align: center;
`;

const AppDownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_LARGE};
  background-color: ${accent.sand150};
  padding: ${SPACING_24};
  max-width: 400px;
  width: 100%;
  text-align: center;
`;

const SelfDeclarationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
  margin-bottom: ${SPACING_32};
  text-align: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  max-width: 200px;
`;

const BadgeWrapper = styled.div`
  display: flex;
  gap: ${SPACING_8};
  justify-content: center;
`;

export const CompletedIdentificationPage = () => {
  const showSelfDeclaration = false;

  return (
    <PageWrapper>
      <SpriteIcon id="Competence" size={72} />
      <TextWrapper>
        <H1Small>{t('identification:completed:title')}</H1Small>
        <ParagraphSmall>
          {t('identification:completed:description')}
        </ParagraphSmall>
      </TextWrapper>

      {showSelfDeclaration ? (
        <SelfDeclarationWrapper>
          <ParagraphSmall style={{ fontWeight: 500 }}>
            {t('identification:completed:selfDeclaration')}
          </ParagraphSmall>
          <StyledButton>
            {t('identification:completed:selfDeclarationButton')}
          </StyledButton>
        </SelfDeclarationWrapper>
      ) : null}

      <AppDownloadWrapper>
        <Paragraph>{t('identification:completed:appDownload')}</Paragraph>

        <BadgeWrapper>
          <AppStoreBadge height="40" />
          <PlayStoreBadge height="40" />
        </BadgeWrapper>
      </AppDownloadWrapper>
    </PageWrapper>
  );
};
