import React, { useState } from 'react';
import validator from 'validator';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { t, config } from '@formue-app/core';

import { PageWrapper } from './base/PageWrapper';
import {
  H1Small,
  Paragraph,
  ParagraphSmall,
  ParagraphXSmall,
} from '@formue-app/web/src/components/texts';
import { Input } from '@formue-app/web/src/components/formElements';
import { Button } from '@formue-app/web/src/components/buttons';

import {
  SPACING_20,
  SPACING_24,
  SPACING_32,
  SPACING_8,
} from '@formue-app/web/src/constants/spacing';

import { ReactComponent as OtpIcon } from '../assets/otp-icon.svg';
import { uiNegative } from '@formue-app/web/src/constants/colors';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_20};
  margin-top: ${SPACING_24};
  margin-bottom: ${SPACING_32};
  text-align: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  margin-bottom: ${SPACING_24};
  max-width: 400px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  max-width: 400px;
  width: 100%;
`;

export const OneTimePasswordPage = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [status, setValidateStatus] = useState({
    otp: false,
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const errorMessages = (error) => {
    switch (error) {
      case 'invalid-otp':
        return t('identification:errorMessages:invalidOtp');
      case 'expired-otp':
        return t('identification:errorMessages:expiredOtp');
      default:
        return t('identification:errorMessages:default');
    }
  };

  const onOtpChange = (event) => {
    setValidateStatus({
      ...status,
      otp:
        validator.isNumeric(event?.target?.value) &&
        validator.isLength(event?.target?.value, { min: 6, max: 6 }),
    });
    setOtp(event?.target?.value);
  };

  const supportMail = t('identification:error:supportMail', {
    context: config.countryCode,
  });

  const handleSubmit = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_HOST}/auth/identification`;
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Basic Zm9ybXVlLWFwcDpzZWNyZXQ=',
    };
    const body = JSON.stringify({
      otp,
      successUrl: `${window.location.origin}/completed`,
      errorUrl: `${window.location.origin}/error`,
    });

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body,
    });

    const result = await response.json();

    console.log(response);

    if (response.ok) {
      dispatch({ type: 'SET_IDENTIFIED_USER', payload: result });

      history.push('/identity');
    } else {
      setValidateStatus({
        ...status,
        otp: false,
      });
      setOtp('');

      setErrorMessage(errorMessages(result.error));
    }
    setLoading(false);
  };

  const disabled = Object.values(status).some((item) => item === false);

  return (
    <PageWrapper>
      <OtpIcon />
      <TextWrapper>
        <H1Small>{t('identification:oneTimePassword:title')}</H1Small>
        <Paragraph>{t('identification:oneTimePassword:description')}</Paragraph>
      </TextWrapper>
      <InputWrapper>
        <Input
          label={t('identification:oneTimePassword:label')}
          value={otp}
          placeholder={t('identification:oneTimePassword:placeholder')}
          onChange={onOtpChange}
          popoutLabel={true}
          inputMode="numeric"
          error={status.otp}
        />
        {errorMessage ? (
          <ParagraphXSmall style={{ textAlign: 'center', color: uiNegative }}>
            {errorMessage}{' '}
            <a style={{ color: uiNegative }} href={`mailto:${supportMail}`}>
              {supportMail}
            </a>
          </ParagraphXSmall>
        ) : null}
        <ParagraphSmall style={{ textAlign: 'center' }}>
          {t('identification:oneTimePassword:helperText')}
        </ParagraphSmall>
      </InputWrapper>
      <StyledButton disabled={disabled || loading} onClick={handleSubmit}>
        {t('identification:oneTimePassword:button')}
      </StyledButton>
    </PageWrapper>
  );
};
