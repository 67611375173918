import { createStore } from 'redux';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const defaultState = {
  language: 'nb',
  languages: {
    nb: 'nb',
    sv: 'sv',
    da: 'da',
    en: 'en',
  },
  userInfo: null,
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.language,
      };
    case 'SET_IDENTIFIED_USER':
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
}

export const store = createStore(reducer, defaultState);
