import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { t } from '@formue-app/core';

import { PageWrapper } from './base/PageWrapper';
import {
  H1Small,
  Paragraph,
  ParagraphSmall,
} from '@formue-app/web/src/components/texts';
import { Button } from '@formue-app/web/src/components/buttons';
import { SpriteIcon } from '@formue-app/web/src/components/common/SpriteIcon';
import { Modal } from '@formue-app/web/src/components/common/Modal';

import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_20,
  SPACING_24,
  SPACING_32,
  SPACING_64,
} from '@formue-app/web/src/constants/spacing';
import { accent, backgroundWhite } from '@formue-app/web/src/constants/colors';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_20};
  margin-top: ${SPACING_64};
  margin-bottom: ${SPACING_32};
  text-align: center;
`;

const Disclamer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-bottom: ${SPACING_64};
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  max-width: 400px;
  width: 100%;
`;

const PersonaliaWrapper = styled.ul`
  border-radius: ${BORDER_RADIUS_LARGE};
  background-color: ${backgroundWhite};
  padding: 0 ${SPACING_24};
  list-style: none;
  max-width: 400px;
  width: 100%;
  margin: 0;
  margin-bottom: ${SPACING_20};

  li {
    display: flex;
    align-items: center;
    gap: ${SPACING_16};
    padding: ${SPACING_20} 0;
    border-bottom: 1px solid ${accent.sand1};

    &:last-child {
      border-bottom: none;
    }

    p {
      font-weight: 500;
    }
  }
`;

export const IdentityPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const history = useHistory();

  useEffect(() => {
    if (!userInfo) {
      history.push('/');
    }
  }, [userInfo, history]);

  if (!userInfo) {
    return null;
  }

  return (
    <PageWrapper>
      <TextWrapper>
        <H1Small>{t('identification:identity:title')}</H1Small>
        <Paragraph>{t('identification:identity:description')}</Paragraph>
      </TextWrapper>

      <PersonaliaWrapper>
        <li>
          <SpriteIcon id="Individual" navigational={true} size={20} />
          <Paragraph>{`${userInfo.firstName} ${userInfo.lastName}`}</Paragraph>
        </li>
        <li>
          <SpriteIcon id="Contact Mail" navigational={true} size={20} />
          <Paragraph>{userInfo.email}</Paragraph>
        </li>
        <li>
          <SpriteIcon id="Contact Phone" navigational={true} size={20} />
          <Paragraph>{userInfo.mobilePhone}</Paragraph>
        </li>
      </PersonaliaWrapper>

      <Disclamer onClick={() => setOpenModal(true)}>
        <SpriteIcon id="Info" navigational={true} />
        <ParagraphSmall>
          {t('identification:identity:disclaimer')}
        </ParagraphSmall>
      </Disclamer>

      <StyledButton to={userInfo.signingUrl} external={true} target="_self">
        {t('identification:identity:button')}
      </StyledButton>

      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        contentWidth={320}
        padding={SPACING_24}
      >
        <Paragraph
          style={{
            fontWeight: 500,
            marginBottom: SPACING_16,
            marginTop: SPACING_24,
          }}
        >
          {t('identification:identity:modalTitle')}
        </Paragraph>
        <Paragraph>{t('identification:identity:modalContent')}</Paragraph>
      </Modal>
    </PageWrapper>
  );
};
