import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AsyncStorage, i18n, config } from '@formue-app/core';

import { GlobalFonts } from '@formue-app/web/src/fonts';
import { ResetStyles } from './reset';

import { OneTimePasswordPage } from './pages/OneTimePasswordPage';
import { IdentityPage } from './pages/IdentityPage';
import { CompletedIdentificationPage } from './pages/CompletedIdentificationPage';
import { ErrorPage } from './pages/ErrorPage';

const App = () => {
  const dispatch = useDispatch();

  document.title = `${config.title}`;

  useEffect(() => {
    const setLanguage = async () => {
      await AsyncStorage.setItem('language', i18n.locale);

      dispatch({
        type: 'SET_LANGUAGE',
        language: i18n.locale,
      });
    };

    setLanguage();
  }, [dispatch]);

  // Hack to reload language on change
  useSelector((state) => state.language);

  return (
    <>
      <ResetStyles />
      <GlobalFonts />
      <Router>
        <Switch>
          <Route path="/identity">
            <IdentityPage />
          </Route>
          <Route path="/completed">
            <CompletedIdentificationPage />
          </Route>
          <Route path="/error">
            <ErrorPage />
          </Route>
          <Route path="/">
            <OneTimePasswordPage />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
