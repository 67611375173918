import React from 'react';
import styled from 'styled-components';

import { t, config } from '@formue-app/core';

import { PageWrapper } from './base/PageWrapper';

import {
  SPACING_20,
  SPACING_24,
  SPACING_64,
} from '@formue-app/web/src/constants/spacing';

import { H1Small, ParagraphSmall } from '@formue-app/web/src/components/texts';
import { SpriteIcon } from '@formue-app/web/src/components/common/SpriteIcon';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_20};
  margin-top: ${SPACING_64};
  margin-bottom: ${SPACING_24};
  text-align: center;
`;

export const ErrorPage = () => {
  const supportMail = t('identification:error:supportMail', {
    context: config.countryCode,
  });

  return (
    <PageWrapper>
      <SpriteIcon id="Warning" size={72} />
      <TextWrapper>
        <H1Small>{t('identification:error:title')}</H1Small>
        <ParagraphSmall>{t('identification:error:description')}</ParagraphSmall>
        <ParagraphSmall>
          {t('identification:error:contactSupport')}{' '}
          <a href={`mailto:${supportMail}`}>{supportMail}</a>.
        </ParagraphSmall>
      </TextWrapper>
    </PageWrapper>
  );
};
