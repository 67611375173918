import styled from 'styled-components';

import { accent } from '@formue-app/web/src/constants/colors';
import { Logo } from '@formue-app/web/src/components/common/Logo';
import {
  SPACING_16,
  SPACING_24,
  SPACING_40,
} from '@formue-app/web/src/constants/spacing';
import { LanguageSelectorDropDown } from '../../components/LanguageSelectorDropDown';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${accent.sand1};
  width: 100%;
  height: 100%;
  padding: ${SPACING_24};
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 390px;
`;

const StyledLanguageSelectorDropDown = styled(LanguageSelectorDropDown)`
  position: absolute;
  top: ${SPACING_16};
  right: ${SPACING_24};

  &:hover {
    background-color: ${accent.sand150};
  }
`;

export const PageWrapper = ({ children }) => (
  <Wrapper>
    <InnerWrapper>
      <Logo height={20} style={{ marginBottom: SPACING_40 }} />
      <StyledLanguageSelectorDropDown />
      {children}
    </InnerWrapper>
  </Wrapper>
);
